<template>
  <el-container style="height: 100%" @dragenter.native="dragenter">
    <el-header style="padding: 0; height: 40px">
      <div id="navigation">回收站</div>
    </el-header>

    <el-container style="height: calc(100% - 40px)">
      <div style="padding: 20px 20px 0 20px">
        <el-form :inline="true" ref="dataForm" :model="dataForm" @keyup.enter.native="getDataList(true)">
          <el-form-item prop="name">
            <el-input v-model="dataForm.name" placeholder="文件名称"></el-input>
          </el-form-item>
          <el-form-item prop="date">
            <el-date-picker
              v-model="dataForm.date"
              type="daterange"
              align="right"
              unlink-panels
              range-separator="至"
              start-placeholder="开始日期"
              end-placeholder="结束日期"
              :picker-options="pickerOptions"
              value-format="yyyy-MM-dd"
            >
            </el-date-picker>
          </el-form-item>
          <el-form-item>
            <el-button @click="getDataList(true)" type="primary">查询</el-button>
          </el-form-item>
          <el-form-item>
            <el-button @click="refreshForm()" style="background: rgb(30, 30, 242, 0.1); color: #5053dd">重置</el-button>
          </el-form-item>
          <el-form-item>
            <el-button @click="restore()" type="primary" icon="el-icon-restore" :disabled="!dataListSelections.length">还原</el-button>
          </el-form-item>
          <el-form-item>
            <el-button @click="deleteHandle()" type="danger" icon="el-icon-delete" :disabled="!dataListSelections.length">彻底删除</el-button>
          </el-form-item>
          <el-form-item>
            <el-button @click="clear()" type="danger" icon="el-icon-delete" :disabled="!dataList.length">清空回收站</el-button>
          </el-form-item>
        </el-form>
      </div>

      <el-main style="padding: 0 20px 20px 20px; overflow: hidden">
        <div style="height: 1px; background: #d4d6d9; opacity: 0.5; margin-bottom: 22px"></div>
        <div class="main">
          <el-table
            v-if="dataList.length"
            :data="dataList"
            v-loading="tabLoding"
            @selection-change="selectionChangeHandle"
            header-cell-class-name="tmc_table_header"
            row-class-name="tmc_table_row"
            style="width: 100%"
            height="calc(100vh - 367px)"
          >
            <el-table-column type="selection" header-align="center" align="center" width="50"> </el-table-column>
            <el-table-column prop="name" header-align="center" align="center" label="文件名" min-width="500">
              <template slot-scope="scope">
                <div style="display: flex; justify-content: center; align-items: center">
                  <div>
                    {{ scope.row.name }}
                  </div>
                  <el-popover placement="right" width="200" trigger="hover" v-if="['jpg', 'jpeg'].includes(scope.row.suffix)">
                    <el-image style="width: 200px; height: 200px" :src="scope.row.url" fit="contain"></el-image>
                    <div slot="reference" style="cursor: pointer; margin-left: 20px">
                      <i class="el-icon-more"></i>
                    </div>
                  </el-popover>
                </div>
              </template>
            </el-table-column>
            <el-table-column prop="suffix" header-align="center" align="center" label="文件类型" width="150"> </el-table-column>
            <el-table-column prop="size" header-align="center" align="center" label="文件大小" width="150">
              <template slot-scope="scope"> {{ scope.row.size }}KB </template>
            </el-table-column>
            <el-table-column prop="updateTime" header-align="center" align="center" label="删除时间" width="200"> </el-table-column>
            <el-table-column prop="day" header-align="center" align="center" label="有效时间" width="100">
              <span slot="header">
                有效时间
                <el-tooltip placement="top">
                  <div slot="content">提示：回收站文件十天后会自动彻底删除</div>
                  <i class="el-icon-info"></i>
                </el-tooltip>
              </span>
              <template slot-scope="scope"> {{ scope.row.day }}天 </template>
            </el-table-column>
            <el-table-column prop="source" header-align="center" align="center" label="源路径" show-overflow-tooltip min-width="250">
            </el-table-column>
          </el-table>
          <div v-else style="height: 100%; text-align: center">
            <el-image :src="require('@/assets/img/no-data.png')" fit="contain" style="margin: 10% 0 24px 0"> </el-image>
            <div style="font-size: 14px; font-family: PingFangSC-Regular, PingFang SC; font-weight: 400; color: #73767a">啊哦~这里没有数据呢~</div>
          </div>
        </div>
      </el-main>
      <el-footer>
        <div v-if="dataList.length" style="margin-top: 20px">
          <el-pagination
            @size-change="sizeChangeHandle"
            @current-change="currentChangeHandle"
            :current-page="pageIndex"
            :page-sizes="[10, 20, 50, 100]"
            :page-size="pageSize"
            :total="totalPage"
            layout="total, sizes, prev, pager, next, jumper"
          >
          </el-pagination>
        </div>
      </el-footer>
    </el-container>
  </el-container>
</template>

<script>
import { changeFileUrl } from '@/utils/changeUrl.js'
export default {
  name: 'Recycle',
  data() {
    return {
      pickerOptions: {
        shortcuts: [
          {
            text: '最近一周',
            onClick(picker) {
              const end = new Date()
              const start = new Date()
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 7)
              picker.$emit('pick', [start, end])
            }
          },
          {
            text: '最近一个月',
            onClick(picker) {
              const end = new Date()
              const start = new Date()
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 30)
              picker.$emit('pick', [start, end])
            }
          },
          {
            text: '最近三个月',
            onClick(picker) {
              const end = new Date()
              const start = new Date()
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 90)
              picker.$emit('pick', [start, end])
            }
          }
        ]
      },
      dataForm: {
        name: '',
        date: []
      },
      pageIndex: 1,
      pageSize: 10,
      totalPage: 0,
      tabLoding: false,
      dataListSelections: [],
      dataList: []
    }
  },
  created() {
    this.getDataList()
  },
  methods: {
    // 清空
    clear() {
      this.$confirm(
        `<div >
        <div class="tmc_confirm_icon"></div>
        <div class="tmc_confirm_title">提示</div> 
        <div class="tmc_confirm_content">清空回收站将彻底失去站内文件，您确定要彻底删除所有站内文件吗？</div> </div>`,
        {
          confirmButtonText: '确定',
          showCancelButton: true,
          dangerouslyUseHTMLString: true,
          center: true,
          customClass: 'tmc_confirm'
        }
      )
        .then(() => {
          this.$http({
            url: this.$http.adornUrl('/api/v1/recycle/clean'),
            method: 'delete',
            data: this.$http.adornData([], false, 'text')
          }).then((res) => {
            if (res.code === 0) {
              this.$store.commit('updateCapacity')
              this.$message({
                message: '操作成功',
                type: 'success',
                duration: 1500,
                onClose: () => {
                  this.getDataList()
                }
              })
            } else {
              this.$message.error(res.msg)
            }
          })
        })
        .catch(() => {})
    },
    // 删除
    deleteHandle() {
      this.$confirm(
        `<div >
          <div class="tmc_confirm_icon"></div>
          <div class="tmc_confirm_title">提示</div> 
          <div class="tmc_confirm_content">文件删除后将无法恢复，您确定要彻底删除所选文件吗？</div> 
        </div>`,
        {
          confirmButtonText: '确定',
          showCancelButton: true,
          dangerouslyUseHTMLString: true,
          center: true,
          customClass: 'tmc_confirm'
        }
      )
        .then(() => {
          this.$http({
            url: this.$http.adornUrl('/api/v1/recycle/clean'),
            method: 'delete',
            data: this.$http.adornData(this.dataListSelections, false, 'text')
          }).then((res) => {
            if (res.code === 0) {
              this.$store.commit('updateCapacity')
              this.$message({
                message: '操作成功',
                type: 'success',
                duration: 1500,
                onClose: () => {
                  this.getDataList()
                }
              })
            } else {
              this.$message.error(res.msg)
            }
          })
        })
        .catch(() => {})
    },
    // 还原
    restore() {
      this.$http({
        url: this.$http.adornUrl('/api/v1/recycle/restore'),
        method: 'post',
        data: this.$http.adornData(this.dataListSelections, false, 'text')
      }).then((res) => {
        if (res.code === 0) {
          if (res.data) {
            this.$confirm(
              `<div >
                <div class="tmc_confirm_icon"></div>
                <div class="tmc_confirm_title">提示</div>
                <div class="tmc_confirm_content">因存在重名文件，部分文件还原至源路径会更名为形如name(n).jpg</div>
              </div>`,
              {
                confirmButtonText: '确定',
                showCancelButton: false,
                dangerouslyUseHTMLString: true,
                center: true,
                customClass: 'tmc_confirm'
              }
            )
              .then(() => {})
              .catch(() => {})
          }
          this.getDataList()
          this.$message({
            message: '操作成功',
            type: 'success',
            duration: 1500,
            onClose: () => {}
          })
        } else {
          this.$message.error(res.msg)
        }
      })
    },
    getDataList(flag) {
      if (flag) {
        this.pageIndex = 1
      }
      this.$http({
        url: this.$http.adornUrl('/api/v1/recycle/list'),
        method: 'get',
        params: this.$http.adornParams({
          name: this.dataForm.name,
          begin: this.dataForm.date[0] ? this.dataForm.date[0] + ' 00:00:00' : undefined,
          end: this.dataForm.date[1] ? this.dataForm.date[1] + ' 23:59:59' : undefined,
          pageNum: this.pageIndex,
          pageSize: this.pageSize
        })
      })
        .then((res) => {
          if (res.code === 0) {
            const data = res.data || {}
            const list = data.records || []

            // 解决全删空白问题
            if (list.length === 0 && this.pageIndex !== 1) {
              this.pageIndex--
              this.getDataList()
              return
            }

            const now = new Date().getTime()
            list.forEach((item) => {
              // 文件类型
              item.suffix = (item.name || '').split('.').reverse()[0]
              // 有效时间
              item.day = Math.floor(10 - (now - Date.parse(item.updateTime)) / (1000 * 3600 * 24))
              // 源路径
              item.source = item.special ? (item.specialType === 1 ? '智能拍摄集' : '诊断规则集') : item.folder
              // 图片地址
              if (['jpg', 'jpeg'].includes(item.suffix)) {
                if (item.path) {
                  item.url = changeFileUrl(item.path)
                } else {
                  this.$http({
                    url: this.$http.adornUrl('/api/v1/files/downloadFile'),
                    method: 'post',
                    params: this.$http.adornParams({
                      deviceId: item.deviceId,
                      name: item.name,
                      size: 0
                    })
                  }).then((res) => {
                    if (res.code === 0) {
                      this.$set(item, 'url', res.data)
                    }
                  })
                }
              }
            })

            this.dataList = list
            this.totalPage = data.total
          } else {
            this.$message.error(res.msg)
          }
          this.tabLoding = false
        })
        .catch(() => {
          this.tabLoding = false
        })
    },
    // 每页数
    sizeChangeHandle(val) {
      this.pageSize = val
      this.pageIndex = 1
      this.getDataList()
    },
    // 当前页
    currentChangeHandle(val) {
      this.pageIndex = val
      this.getDataList()
    },
    // 多选
    selectionChangeHandle(val) {
      this.dataListSelections = val
    },
    refreshForm() {
      this.$refs.dataForm.resetFields()
    }
  }
}
</script>

<style lang="scss" scoped>
.main {
  overflow: hidden;
}
#navigation {
  width: 100%;
  height: 40px;
  background: #f4f4f4;
  font-size: 12px;
  font-family: PingFangSC-Medium, PingFang SC;
  font-weight: bold;
  margin-bottom: 22px;
}
</style>
